<template>
  <div>
    <div class="top">
      <el-row>
        <el-col>
          <p class="title"><span style="color: #ff4949"> *</span> 人员产品线</p>
          <el-select
            v-model="formInline.productLine"
            placeholder="请选择人员产品线"
            size="small"
          >
            <el-option
              v-for="(item, index) in peopleType"
              :key="item"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div class="select-card">
      <div class="select-card-top">
        <div class="title">代表销量分析</div>
      </div>
      <div class="select-card-bottom">
        <el-row>
          <el-form
            :model="formInline"
            label-width="120px"
            width="100%"
            :rules="rules"
            ref="formInline"
          >
            <el-form-item label="组织架构筛选">
              <el-cascader
                collapse-tags
                v-model="formInline.deptCodeList"
                :options="data_tree"
                :props="{
                  multiple: true,
                  label: 'name',
                  value: 'code',
                  checkStrictly: true,
                }"
                placeholder="请选择组织架构"
                size="small"
                style="min-width: 160px"
                ref="myCascader"
                filterable
                show-all-levels
                @change="handleChange"
              >
                <template #default="{ data }">
                  <span>{{ data.name }}</span>
                  <span v-if="data.delFlag != 0">(旧)</span>
                </template>
              </el-cascader>
            </el-form-item>
            <el-form-item label="时间选择" prop="bindingDate">
              <el-date-picker
                v-model="formInline.bindingDate"
                type="month"
                placeholder="请按月份选择"
                size="small"
                value-format="yyyyMM"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <!--  
                :disabled='!btnAuth&&btnAuth.indexOf("17")>-1'
                 :disabled='!btnAuth&&btnAuth.indexOf("18")>-1'
                 :disabled='!btnAuth&&btnAuth.indexOf("19")>-1'
               -->
              <div class="flex" style="float: right">
                <el-button
                  type="primary"
                  size="small"
                  @click="initList('formInline')"
                  :disabled='!flag17'
                  >查询</el-button
                >
                <el-button type="primary" size="small" @click="downSales()"  :disabled='!flag18'
                  >导出客户管理</el-button
                >
                <el-button type="primary" size="small" @click="export2Doctor()" :disabled='!flag19'
                  >导出费用明细</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
    </div>
    <div class="select-card" style="margin-top: 24px">
      <div class="select-card-bottom">
        <div
          class="select-card-bottom-left"
          style="margin-top: 13px; width: 100%"
        >
          <el-table
            :data="tableData"
            style="width: 100%"
            max-width="100%"
            :cell-style="colStyle"
            id="show"
          >
            <el-table-column
              label="客户档案"
              style="width: 100%"
              align="center"
            >
              <el-table-column type="index" width="80" label="序号">
              </el-table-column>
              <el-table-column label="组织架构" width="120" class="red" fixed>
                <template slot-scope="scope">
                  <span>{{ scope.row?.employee?.tdepartment?.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="oaName" label="代表" width="120">
              </el-table-column>
              <el-table-column label="医院名称" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.doctor?.hospitalName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="科室" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.doctor?.department }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="doctorId" label="医生ID" width="120">
              </el-table-column>
              <el-table-column prop="doctorName" label="医生姓名" width="120">
              </el-table-column>
              <el-table-column label="绑定状态" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctor?.enable == 1 ? "已绑定" : "已解绑"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="电话" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.doctor?.phone }}</span>
                </template>
              </el-table-column>
              <el-table-column label="职称" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.doctor?.title }}</span>
                </template>
              </el-table-column>
              <el-table-column label="职务" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.doctor?.jobTitle }}</span>
                </template>
              </el-table-column>
              <el-table-column label="治疗领域" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.therapyArea
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="个人信息" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.personalInformation
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="管理病床数" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.bedsNumber
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="周转率" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.turnoverRate
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="zip" label="相关患者比例" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.patientsProportion
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="相关患者数" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.patientsNumber
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="zip" label="潜力" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.doctorProductLinkUser?.potential }}</span>
                </template>
              </el-table-column>
              <el-table-column label="观念分级" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.senseGrade
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="KOL分级" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.doctorProductLinkUser?.kolGrade }}</span>
                </template>
              </el-table-column>
              <el-table-column label="客户需求" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.customerDemand
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="zip" label="匹配策略" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.doctorProductLinkUser?.matchPolicy
                  }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              :label="`${dateName}月计划预算`"
              style="width: 100%"
              align="center"
            >
              <el-table-column label="预估投入" width="120">
                <template slot-scope="scope">
                  <span @click="tab(scope.row, 1)">{{
                    scope.row?.costDetails?.budgetMoneySum
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="销售投入预算" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.costDetails?.budget_销售_Money }}</span>
                </template>
              </el-table-column>
              <el-table-column label="投入方式" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.costDetails?.budget_销售_InputMode
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="市场投入预算" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.costDetails?.budget_市场_Money }}</span>
                </template>
              </el-table-column>
              <el-table-column label="投入方式" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.costDetails?.budget_市场_InputMode
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="医学投入预算" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.costDetails?.budget_医学_Money }}</span>
                </template>
              </el-table-column>
              <el-table-column label="投入方式" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.costDetails?.budget_医学_InputMode
                  }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              :label="`${dateName}月实际投入`"
              align="center"
              style="width: 100%"
            >
              <el-table-column label="实际总投入" width="120">
                <template slot-scope="scope">
                  <span @click="tab(scope.row, 2)">{{
                    scope.row?.costDetails?.actualMoneySum
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="销售投入" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.costDetails?.actual_销售_Money }}</span>
                </template>
              </el-table-column>
              <el-table-column label="投入方式" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.costDetails?.actual_销售_InputMode
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="市场投入" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.costDetails?.actual_市场_Money }}</span>
                </template>
              </el-table-column>
              <el-table-column label="投入方式" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.costDetails?.actual_市场_InputMode
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="医学投入" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row?.costDetails?.actual_医学_Money }}</span>
                </template>
              </el-table-column>
              <el-table-column label="投入方式" width="120">
                <template slot-scope="scope">
                  <span>{{
                    scope.row?.costDetails?.actual_医学_InputMode
                  }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Dialog v-if="show" :info="info" />
  </div>
</template>
<script>
import get_jg_list from "@/utils/jg";
import watermark from "@/utils/watermark";
import Dialog from "./components/Dialog";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      info: {},
      show: false,
      tableData: [],
      value: "",
      data_tree: [], //组织架构
      peopleType: [], //产品线
      select_data_tree: [],
      dateName: "",
      bindingDate: "",
      formInline: {
        bindingDate: "",
        deptCodeList: [],
        productLine: "",
      },
      text: "",
      btnAuth: "",
      rules: {
        bindingDate: [
          { required: true, message: "请选择日期", trigger: "blur" },
        ],
      },
      flag17:false,
      flag18:false,
      flag19:false,
    };
  },
  created() {
    this.defaultDate();
    if (sessionStorage.getItem("userInfo")) {
      this.text = JSON.parse(sessionStorage.getItem("userInfo")).name;
      this.btnAuth = JSON.parse(sessionStorage.getItem("userInfo")).authIds;
      console.log(this.btnAuth, "this.btnAuth");
      this.flag18 = this.btnAuth.indexOf("18")>-1;
      this.flag17 = this.btnAuth.indexOf("17")>-1;
      this.flag19 = this.btnAuth.indexOf("19")>-1;
      console.log(this.flag19,this.flag18,this.flag17, "this.flag");
    }
    this.initData();
  },
  mounted() {
    watermark.set(this.text);
  },
  methods: {
    defaultDate() {
      //获取新的时间
      let date = new Date();
      //获取当前时间的年份转为字符串
      let year = date.getFullYear().toString();
      //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : (date.getMonth() + 1).toString();
      //获取天，判断是否小于10，如果是在字符串前面拼接'0'
      let beg = year + month; //当月第一天
      this.formInline.bindingDate = beg; //将值设置给插件绑定的数据
      this.dateName = this.insertStr(beg, 4, "年"); // 2020-0808
    },
    insertStr(source, start, newStr) {
      return source.slice(0, start) + newStr + source.slice(start);
    },
    async initData() {
      let { data_tree } = await get_jg_list.get_jg_list(1);
      this.data_tree = data_tree;
      this.axios.get("DOCTOR_ARCHIVES_DROPDOW", "").then((result) => {
        console.log("获取人员产品线", result);
        if (result.code == 0) {
          this.peopleType = result.data.userProductLine;
          this.formInline.productLine = Object.keys(this.peopleType)[0];
        } else {
          this.$message.error(result.msg);
        }
        // this.initList("formInline");
        // 重新组装结构
      });
    },
    colStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 22 || columnIndex == 29) {
        return { color: "#3BAF61", "font-weight": 500 };
      }
    },
    initList(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.formInline));
          let arr = [];
          data.deptCodeList.forEach((ele) => {
            arr.push(ele[ele.length - 1]);
          });
          console.log(arr);
          data.deptCodeList = arr;
          if (!data.productLine) {
            this.$message.error("请选择人员产品线");
            return false;
          }

          console.log(this.formInline, "this.formInline");
          this.axios.post("DOCTOR_ARCHIVES_LIST", data).then((result) => {
            console.log("获取交易概览选择列表", result);
            if (result.code == 0) {
              this.tableData = result.data;

              // watermark.set(this.text);
            } else {
              this.$message.error(result.msg);
            }
            // 重新组装结构
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange() {},
    close() {
      this.show = false;
    },
    tab(val, type) {
      console.log(11111);
      this.show = true;
      this.info.value = val;
      this.info.type = type;
      this.info.date = this.formInline.bindingDate;
    },
    //客户档案导出
    exportDoctor() {
      let data = JSON.parse(JSON.stringify(this.formInline));
      let arr = [];
      data.deptCodeList.forEach((ele) => {
        arr.push(ele[ele.length - 1]);
      });
      console.log(arr);
      arr.length == 0 ? (arr = "") : (arr = arr.join(","));
      data.deptCodeList = arr;
      if (!data.productLine) {
        this.$message.error("请选择人员产品线");
        return false;
      }
      this.axios.get("DOCTOR_EXPORT", data).then((result) => {
        console.log(result, "123");
        // if (result.code == 0) {
        //   this.$message({
        //     message: "数据导出中,请导出列表查看",
        //     type: "success",
        //   });
        // } else {
        //   this.$message.error(result.msg);
        // }
        if (result instanceof Blob) {
          const blob = new Blob([result]);
          console.log(blob);
          const time = new Date().getTime() / 1000;
          const fileName = "客户档案" + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        } else {
          this.$message.error("导出失败！");
        }
      });
    },
    //费用明细导出
    export2Doctor() {
      let data = JSON.parse(JSON.stringify(this.formInline));
      let arr = [];
      data.deptCodeList.forEach((ele) => {
        arr.push(ele[ele.length - 1]);
      });
      console.log(arr);
      arr.length == 0 ? (arr = "") : (arr = arr.join(","));
      data.deptCodeList = arr;
      if (!data.productLine) {
        this.$message.error("请选择人员产品线");
        return false;
      }
      this.axios.get("DOCTOR_EXPORT2", data).then((result) => {
        console.log(result, "123");
        // if (result.code == 0) {
        //   this.$message({
        //     message: "数据导出中,请导出列表查看",
        //     type: "success",
        //   });
        // } else {
        //   this.$message.error(result.msg);
        // }
        if (result instanceof Blob) {
          const blob = new Blob([result]);
          console.log(blob);
          const time = new Date().getTime() / 1000;
          const fileName = "费用明细" + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        } else {
          this.$message.error("导出失败！");
        }
      });
    },
    downSales() {
      let data = JSON.parse(JSON.stringify(this.formInline));
      let arr = [];
      data.deptCodeList.forEach((ele) => {
        arr.push(ele[ele.length - 1]);
      });
      console.log(arr);
      arr.length == 0 ? (arr = "") : (arr = arr.join(","));
      data.deptCodeList = arr;
      this.axios
        .get("DOCTORSALESVOLUMEANALYSIS_DOWNLOAD", data)
        .then((result) => {
          console.log(result, "123");
          if (result.code == 0) {
            this.$message({
              message: "数据导出中,请导出列表查看",
              type: "success",
            });
          } else {
            this.$message.error(result.msg);
          }
          // if(result instanceof Blob){
          //   const blob = new Blob([result]);
          // console.log(blob);
          // const time = new Date().getTime() / 1000;
          // const fileName ="费用明细"+ ".xlsx";
          // if ("download" in document.createElement("a")) {
          //   // 非IE下载
          //   const elink = document.createElement("a");
          //   elink.download = fileName;
          //   elink.style.display = "none";
          //   elink.href = URL.createObjectURL(blob);
          //   document.body.appendChild(elink);
          //   elink.click();
          //   URL.revokeObjectURL(elink.href); // 释放URL 对象
          //   document.body.removeChild(elink);
          // } else {
          //   // IE10+下载
          //   navigator.msSaveBlob(blob, fileName);
          // }
          // }else{
          //   this.$message.error("导出失败！");
          // }
        });
    },
  },
};
</script>

<style>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 20px;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .btnClass {
    width: 100%;
    height: 28px;
    background: rgba(0, 178, 88, 0.1);
    border-radius: 16px;
    color: #00b258;
    border: none;
    text-align: center;
    span {
      display: inline-block;
      width: 100%;
      line-height: 28px;
      text-align: center;
    }
  }
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      flex: 1;
    }
    /deep/ .el-checkbox__label {
      display: inline-block;
      padding-left: 10px;
      line-height: 19px;
      font-size: 12px;
    }
    .check_con {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 17px;
      letter-spacing: 1px;
      margin-right: 24px;
    }
    .btn {
      // margin-right: 12px;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    // display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex: 1;
      overflow: scroll;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
.top {
  margin-bottom: 24px;
  padding: 0 24px 0 24px;
  .title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
    font-weight: 700;
    // margin-right:10px ;
  }
}
/deep/ .el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #d4dde1;
}
/deep/ .el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  color: #5c6474;
}
#show {
  position: relative;
  z-index: 999;
  bottom: 0;
  overflow: hidden;
}
/deep/ .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0.5rem;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  height: 0.5rem;
}
/deep/
  .el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0.625rem;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

/deep/
  .el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 0.3125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  background: #ffffff;
}

/deep/
  .el-table--scrollable-x
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0.625rem;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #dddddd;
}
/deep/
  .el-table--scrollable-x
  .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 0.3125rem rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  background: #ffffff;
}
</style>
