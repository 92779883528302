<template>
  <el-dialog :title="title" :visible.sync="dialogTableVisible" @close="handleCancle" algin="center">
    <el-table :data="actualList" v-if='info.type==2' >
      <el-table-column
        property="timeCreate"
        label="日期"
      ></el-table-column>
      <el-table-column
        property="costType"
        label="费用类型"
       
      ></el-table-column>
      <el-table-column property="inputMode" label="投入方式"></el-table-column>
      <el-table-column property="money" label="金额"></el-table-column>
    </el-table>
    <!-- 预算 -->
    <el-table :data="budgetList" v-if='info.type==1'>
      <el-table-column
        property="timeCreate"
        label="日期"
       
      ></el-table-column>
      <el-table-column
        property="costType"
        label="费用类型"
       
      ></el-table-column>
      <el-table-column property="inputMode" label="投入方式"></el-table-column>
      <el-table-column property="money" label="金额"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  props: {
    info: [Object],
  },
  data() {
    return {
      dialogTableVisible: true,
      actualList:[],
      budgetList:[],
      title:""
    };
  },
  created() {
    console.log(this.info);
    this.info.type==1?this.title='预估投入详情':this.title='实际费用详情'
    this.getDetail()
  },
  methods: {
    handleCancle(){
        console.log('1111')
        this.$parent.close()
    },
    getDetail() {
      let params = {
        costDate: this.info.date,
        doctorId: this.info?.value?.doctorId,
        oaAccount: this.info?.value?.oaAccount,
      };
      this.axios
        .post("COST_DETAILS", params)
        .then((result) => {
          console.log(result);
          this.actualList = result.data.actualList
          this.budgetList = result.data.budgetList
        })
        .catch(() => {
          console.log(error);
        });
    },
  },
};
</script>
